/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';

const API_PATH = process.env.REACT_APP_API_PATH;
const API_PUT = `${API_PATH}/newOrder`;
const API_GET = `${API_PATH}/query`;
const PRICE = process.env.REACT_APP_PRICE;

export default function Form({ param }) {
  const [open, setOpen] = React.useState(true);
  const [payStatus,setPayStatus] = React.useState(false);
  const [request,setRequest] = React.useState({
    Name:'',
    Amount:1,
    Phone:'',
    Country:'中国',
    Code:'',
    Address:'',
    Key:sessionStorage.getItem('token')
  });
  const timerComponents = [];
  const [result,setResult] = React.useState(null);
  const [timeLeft, setTimeLeft] = useState({});
  const [isLost, setIsLost] = React.useState(false);
  const countries = [
    { name: "阿富汗", region: 1},
    { name: "亚美尼亚", region: 1},
    { name: "阿塞拜疆", region: 1},
    { name: "巴林", region: 1},
    { name: "孟加拉国", region: 1},
    { name: "不丹", region: 1},
    { name: "文莱达鲁萨兰国", region: 1},
    { name: "柬埔寨", region: 1},
    { name: "格鲁吉亚", region: 1},
    { name: "印度", region: 1},
    { name: "印度尼西亚", region: 1},
    { name: "伊朗（伊斯兰共和国）", region: 1},
    { name: "伊拉克", region: 1},
    { name: "以色列", region: 1},
    { name: "日本", region: 1},
    { name: "约旦", region: 1},
    { name: "哈萨克斯坦", region: 1},
    { name: "朝鲜民主主义人民共和国", region: 1},
    { name: "韩国", region: 1},
    { name: "科威特", region: 1},
    { name: "吉尔吉斯斯坦", region: 1},
    { name: "老挝人民民主共和国", region: 1},
    { name: "黎巴嫩", region: 1},
    { name: "马来西亚", region: 1},
    { name: "马尔代夫", region: 1},
    { name: "蒙古", region: 1},
    { name: "缅甸", region: 1},
    { name: "尼泊尔", region: 1},
    { name: "阿曼", region: 1},
    { name: "巴基斯坦", region: 1},
    { name: "菲律宾", region: 1},
    { name: "卡塔尔", region: 1},
    { name: "俄罗斯联邦", region: 1},
    { name: "沙特阿拉伯", region: 1},
    { name: "新加坡", region: 1},
    { name: "斯里兰卡", region: 1},
    { name: "叙利亚（叙利亚阿拉伯共和国）", region: 1},
    { name: "中国台湾省", region: 1},
    { name: "塔吉克斯坦", region: 1},
    { name: "泰国", region: 1},
    { name: "东帝汶（东帝汶）", region: 1},
    { name: "土耳其", region: 1},
    { name: "土库曼斯坦", region: 1},
    { name: "阿拉伯联合酋长国", region: 1},
    { name: "乌兹别克斯坦", region: 1},
    { name: "越南", region: 1},
    { name: "也门", region: 1},
    { name: "中国", region: 2},
    { name: "香港", region: 2},
    { name: "澳门", region: 2},
    { name: "阿尔巴尼亚", region: 3},
    { name: "安道尔", region: 3},
    { name: "奥地利", region: 3},
    { name: "白俄罗斯", region: 3},
    { name: "比利时", region: 3},
    { name: "波黑", region: 3},
    { name: "保加利亚", region: 3},
    { name: "克罗地亚", region: 3},
    { name: "塞浦路斯", region: 3},
    { name: "捷克共和国", region: 3},
    { name: "丹麦", region: 3},
    { name: "爱沙尼亚", region: 3},
    { name: "芬兰", region: 3},
    { name: "法国", region: 3},
    { name: "德国", region: 3},
    { name: "直布罗陀", region: 3},
    { name: "希腊", region: 3},
    { name: "匈牙利", region: 3},
    { name: "冰岛", region: 3},
    { name: "爱尔兰", region: 3},
    { name: "意大利", region: 3},
    { name: "拉脱维亚", region: 3},
    { name: "列支敦士登", region: 3},
    { name: "立陶宛", region: 3},
    { name: "卢森堡", region: 3},
    { name: "马耳他", region: 3},
    { name: "摩尔多瓦共和国", region: 3},
    { name: "摩纳哥", region: 3},
    { name: "荷兰", region: 3},
    { name: "北马其顿", region: 3},
    { name: "挪威", region: 3},
    { name: "波兰", region: 3},
    { name: "葡萄牙", region: 3},
    { name: "罗马尼亚", region: 3},
    { name: "圣马力诺", region: 3},
    { name: "塞尔维亚和黑山", region: 3},
    { name: "斯洛伐克", region: 3},
    { name: "斯洛文尼亚", region: 3},
    { name: "西班牙", region: 3},
    { name: "瑞典", region: 3},
    { name: "瑞士", region: 3},
    { name: "乌克兰", region: 3},
    { name: "英国", region: 3},
    { name: "梵蒂冈城", region: 3},
    { name: "美属萨摩亚", region: 4},
    { name: "安圭拉", region: 4},
    { name: "安提瓜和巴布达", region: 4},
    { name: "阿鲁巴", region: 4},
    { name: "巴哈马", region: 4},
    { name: "巴巴多斯", region: 4},
    { name: "伯利兹", region: 4},
    { name: "百慕大", region: 4},
    { name: "加拿大", region: 4},
    { name: "开曼群岛", region: 4},
    { name: "哥斯达黎加", region: 4},
    { name: "古巴", region: 4},
    { name: "多米尼克", region: 4},
    { name: "多米尼加共和国", region: 4},
    { name: "萨尔瓦多", region: 4},
    { name: "格陵兰", region: 4},
    { name: "格林纳达", region: 4},
    { name: "瓜德罗普", region: 4},
    { name: "关岛", region: 4},
    { name: "危地马拉", region: 4},
    { name: "海地", region: 4},
    { name: "洪都拉斯", region: 4},
    { name: "牙买加", region: 4},
    { name: "马提尼克", region: 4},
    { name: "墨西哥", region: 4},
    { name: "蒙特塞拉特", region: 4},
    { name: "尼加拉瓜", region: 4},
    { name: "巴拿马", region: 4},
    { name: "波多黎各", region: 4},
    { name: "圣基茨和尼维斯", region: 4},
    { name: "圣卢西亚", region: 4},
    { name: "圣文森特和格林纳丁斯", region: 4},
    { name: "特立尼达和多巴哥", region: 4},
    { name: "特克斯和凯科斯群岛", region: 4},
    { name: "美国", region: 4},
    { name: "美属维尔京群岛", region: 4},
    { name: "阿根廷", region: 5},
    { name: "玻利维亚", region: 5},
    { name: "巴西", region: 5},
    { name: "智利", region: 5},
    { name: "哥伦比亚", region: 5},
    { name: "厄瓜多尔", region: 5},
    { name: "法属圭亚那", region: 5},
    { name: "圭亚那", region: 5},
    { name: "巴拉圭", region: 5},
    { name: "秘鲁", region: 5},
    { name: "苏里南", region: 5},
    { name: "乌拉圭", region: 5},
    { name: "委内瑞拉", region: 5 }
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (!param || param === '' || param === null || param === undefined) {
      const token = sessionStorage.getItem('token');
      if (!token) {
        navigate('/');
      }
    }
  }, [param, navigate]); 
  
  useEffect(() => {
    if (result !== null) {
      function calculateTimeLeft() {
        const difference = new Date(result.last) - new Date();
        let timeLeft = {};

        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }

        return timeLeft;
      };

      setTimeLeft(calculateTimeLeft());
    }
  }, [result]);

  useEffect(() => {
    if (result !== null && +new Date(result.last) > +new Date()) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());  // 直接重新计算时间
      }, 1000);
  
      // 清除interval
      return () => clearInterval(timer);
    }
  }, [result]);

  useEffect(() => {
    if(param !== null && param !== '' && param !== undefined) {
      handleParamQuery()
    }  
  }, [param]);

  const calculateTimeLeft = () => {
    let difference = +new Date(result.last) - +new Date(); // 使用一元加号操作符来转换日期为数字
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }else {
      setIsLost(true)
    }
    return timeLeft;
  };
  const navtoquery = () => {
    navigate('/query')
  }
  const renderTimer = () => {
    if (result === null) {
      return null; 
    }

    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={uuidv4()}>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
    return timerComponents.length ? timerComponents : <span>Time's up!</span>;
  };


  const isValidRequest = (req) => {
    const nonEmptyFields = ['Name', 'Phone', 'Country', 'Code', 'Address'];
    for (const field of nonEmptyFields) {
      if (!req[field].trim()) { 
        return false;
      }
    }

    if (typeof req.Amount === 'string' && (Number(req.Amount) <= 0 || Number(req.Amount) >= 1000)) {
      return false;
    }

    return true; 
  };
  
  
  const handleSubmit = async () => {
    console.log(request)
    handleClose()
    if (!isValidRequest(request)) {  
      handleClickOpen();
      alert('所有栏位不得为空 数量介于1~999之间');   
      return;
    }
    try {
      const response = await axios.post(API_PUT, request);
      if (response.status === 200) {
        navigate(`/${response.data.account}`);
      }
    } catch (error) {
      console.error("Error creating new order:", error);
      alert('提交失败');
    }finally {
      handleClickOpen();
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleQuery = async () => {
    try {
          const response = await axios.post(API_GET, {account:result.account});
          if (response.status === 200 && response.data.msg === undefined) {            
              setPayStatus(response.data.completed)
              setResult(response.data);         
          }else {
            navigate('/');
          }
    } catch (error) {
        console.error("Error logging in:", error);
    }          
  }

  const handleParamQuery = async () => {
    handleClose()
    try {
          const response = await axios.post(API_GET, {account:param});
          if (response.status === 200 && response.data.msg === undefined) {            
              setPayStatus(response.data.completed)
              setResult(response.data)
          }else {
            navigate('/');
          }
    } catch (error) {
        console.error("Error logging in:", error);
    }        
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, propertyName) => {
    const value = event.target ? event.target.value : event;
    setRequest((prevData) => ({
        ...prevData,
        [propertyName]: value,
    }));   
  };

  useEffect(() => {
    let intervalId = null;
    if (result) { 
      intervalId = setInterval(() => {
        handleQuery();
      }, 30000); 
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [result]); 

  useEffect(() => {  
    if (result !== null && result.account === param) { 
        handleClickOpen()
    }
  }, [result]); 

  return (
      
      <div>
        <Dialog open={open}
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
            PaperProps={{
              style: {
                width: '600px',
              },
            }}
        >
          <DialogTitle style={{justifyContent:'center',display:'flex',fontSize:'16px'}}>Why Are You Here?<br></br>{result === null?'Order USB Key':
          null}</DialogTitle>
          <DialogContent>
            {result === null?
              <Box sx={{ flexGrow: 0 }}>
                  <Grid container spacing={2}>
                      <Grid item xs={2} >
                          <Button variant="text" onClick={navtoquery}>查询订单</Button>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex',marginRight:'10px' }}>
                          <TextField
                          fullWidth 
                          id="outlined-number"
                          size="small"
                          label="收件人"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          value={request.Name}
                          onChange={(e) => handleInputChange(e, 'Name')}
                          />
                      </Grid>
                      <Grid item xs={6} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                          <TextField
                          fullWidth 
                          id="outlined-number"
                          size="small"
                          label="数量"
                          type="number"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          value={request.Amount}
                          onChange={(e) => handleInputChange(e, 'Amount')}
                          />
                      </Grid>  
                      <Grid item xs={6} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                          <TextField
                          fullWidth 
                          id="outlined-number"
                          size="small"
                          label="手机号码"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          value={request.Phone}
                          onChange={(e) => handleInputChange(e, 'Phone')}
                          />
                      </Grid>
                      <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                          <FormControl sx={{ mt: 1 }} size="small" fullWidth >
                          <InputLabel id="demo-select-small-label">国家</InputLabel>
                          <Select
                              labelId="country-select-label"
                              id="country-select"
                              value={request.Country}
                              onChange={(e) => handleInputChange(e, 'Country')}
                              label="国家"
                          >
                              {countries.map((country, index) => (
                              <MenuItem key={uuidv4()} value={country.name}>
                                  {country.name}
                              </MenuItem>
                              ))}
                          </Select>
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                          <TextField
                          fullWidth 
                          id="outlined-number"
                          size="small"
                          label="邮政编码"
                          value={request.Code}
                          onChange={(e) => handleInputChange(e, 'Code')}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          />
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                          <TextField
                          fullWidth 
                          id="outlined-number"
                          size="small"
                          label="地址"
                          value={request.Address}
                          onChange={(e) => handleInputChange(e, 'Address')}
                          multiline
                          rows={4}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          />
                      </Grid>
                  </Grid>
              </Box> : 
              <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}> 
                    <Grid item xs={12} sx={{ mb: -3 }} style={{justifyContent:'center',display:'flex',fontSize:'14px' }}> 
                    {`Please send $${Number(result.info.Amount) * PRICE} USDT to below address:`}
                    </Grid>   
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}> 
                    {!isLost ? (
                      !payStatus ? <img src={result.qr} alt='qrcode'/> : <Alert severity="success" style={{marginTop:'10px'}}>交易已完成 我们会尽速寄出您的品项</Alert>
                    ) : (
                      <Alert severity="warning" style={{marginTop:'10px'}}>该订单已失效。</Alert>
                    )}            
                    </Grid>   
                    <Grid item xs={12} sx={{ mt: 0 }} style={{justifyContent:'center',display:'flex',fontSize:'14px' }}> 
                    {result.walletB58}
                    </Grid>            
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex'}}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            {renderTimer()}<br/>
                            订单数量:{result.info.Amount}<br/>
                            总额: {Number(result.info.Amount) * PRICE} USD<br/>
                            收件人:{result.info.Name}<br/>
                            电话:{result.info.Phone}<br/>
                            国家:{result.info.Country}<br/>
                            邮政编码:{result.info.Code}<br/>
                            地址:{result.info.Address}<br/>
                          </Typography>
                          <Typography gutterBottom variant="h6" component="div">
                            订单编号:{result.account}<br/>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                </Grid>
            </Box>}
          </DialogContent>
          <DialogActions>
            {result === null?  <Button onClick={handleSubmit}>送出</Button>:null}      
          </DialogActions>
        </Dialog>
      </div>
    );
  }

