// /:paramUrl
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';

const API_PATH = process.env.REACT_APP_API_PATH;
const API_GET = `${API_PATH}/query`;
const PRICE = process.env.REACT_APP_PRICE;

export default function QueryResult({ queryResult }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [payStatus, setPayStatus] = React.useState(false);
  const timerComponents = [];
  const [result, setResult] = React.useState(queryResult);
  const [timeLeft, setTimeLeft] = useState({});
  const [isLost, setIsLost] = React.useState(false);
  const [isOverTime, setIsOverTime] = React.useState(false);

  // 1698342110051
  useEffect(() => {
    if (result !== null) {
      function calculateTimeLeft() {
        const difference = new Date(result.last) - new Date();
        let timeLeft = {};

        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }

        return timeLeft;
      };

      setTimeLeft(calculateTimeLeft());
    }
  }, [result]);

  useEffect(() => {
    if (result !== null && +new Date(result.last) > +new Date()) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());  // 直接重新计算时间
      }, 1000);

      // 清除interval
      return () => clearInterval(timer);
    }
  }, [result]);

  useEffect(() => {
    if (result.account !== null && result.account !== '' && result.account !== undefined) {
      handleParamQuery()
    }
  }, [result]);

  const calculateTimeLeft = () => {
    let difference = +new Date(result.last) - +new Date(); // 使用一元加号操作符来转换日期为数字
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      setIsLost(true)
    }
    return timeLeft;
  };

  const renderTimer = () => {
    if (result === null) {
      return null;
    }

    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={uuidv4()}>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
    if (timerComponents.length) {
      setIsOverTime(true);
    }
    return timerComponents.length ? timerComponents : <><span>{""/*Time's up!<br>*/}</span></>;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleQuery = async () => {
    try {
      const response = await axios.post(API_GET, { account: result.account });
      if (response.status === 200 && response.data.msg === undefined) {
        setPayStatus(response.data.completed)
        setResult(response.data);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  const handleParamQuery = async () => {
    handleClose()
    try {
      const response = await axios.post(API_GET, { account: result.account });
      if (response.status === 200 && response.data.msg === undefined) {
        setPayStatus(response.data.completed)
        setResult(response.data)
      } else {
        navigate('/');
      }
      await new Promise(resolve => setTimeout(resolve, 5000)); // wait 5s
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let intervalId = null;
    if (result) {
      intervalId = setInterval(() => {
        handleQuery();
      }, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [result]);

  useEffect(() => {
    if (result !== null) {
      handleClickOpen()
    }
  }, [result]);

  return (

    <div>
      <Dialog open={open}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <DialogTitle style={{ justifyContent: 'center', display: 'flex', fontSize: '16px' }}>Why Are You Here?</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 0 }}>
            <Grid container spacing={2}>
              {(!isLost && !payStatus && !isOverTime) ? (
                <Grid item xs={12} sx={{ mb: -3 }} style={{ justifyContent: 'center', display: 'flex', fontSize: '14px' }}>
                  {`Please send $${Number(result.info.Amount) * PRICE} USDT to below address:` /* comment */}
                </Grid>) : null}
              <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                {!isLost ? (
                  !payStatus && !isOverTime ? <img src={result.qr} alt='qrcode' /> : <Alert severity="success" style={{ marginTop: '10px' }}>交易已完成 我们会尽速寄出您的品项</Alert>
                ) : (
                  <Alert severity="warning" style={{ marginTop: '10px' }}>该订单已失效。</Alert>
                )}
              </Grid>
              {((!isLost && payStatus && !isOverTime)) ?
                <>
                  <Grid item xs={12} sx={{ mt: -2 }} style={{ justifyContent: 'center', display: 'flex' }}>
                    <Alert severity="warning" style={{ marginTop: '10px' }}>效期: {new Date(result.info.Expire).toLocaleString()}<br />續用請匯款至同一地址, 金額: ${Number(result.info.Amount) * 30}</Alert>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: -2 }} style={{ justifyContent: 'center', display: 'flex' }}> <img src={result.qr} alt='qrcode' /> </Grid>
                </>
                : null
              }
              <Grid item xs={12} sx={{ mt: 0 }} style={{ justifyContent: 'center', display: 'flex', fontSize: '14px' }}>
                {isOverTime ? (
                  <><s>{result.walletB58}</s> <span>expired</span></>
                ) : (
                  <span>{result.walletB58}</span>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {renderTimer()}
                      订单数量:{result.info.Amount}<br />
                      总额: {Number(result.info.Amount) * PRICE} USD<br />
                      收件人:{result.info.Name}<br />
                      电话:{result.info.Phone}<br />
                      国家:{result.info.Country}<br />
                      邮政编码:{result.info.Code}<br />
                      地址:{result.info.Address}<br />
                      订单建立时间:{result.created.split('T')[0]}<br />
                      订单有效时间:{result.last.split('T')[0]}<br />
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      订单编号:{result.account}<br />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

