import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import MatrixRain from './MatrixRain';
import Form from './form/Form';
import DoorForm from './form/DoorForm';
import QueryForm from './form/QueryForm';
import QueryFromUrlResult from './form/QueryFromUrlResult';

function RouteWithParam() {
  const { param } = useParams(); 
  return <Form param={param} />;
}

function App() {
  return (
    <Router>
      <div style={{ position: 'relative' }}>
        <MatrixRain />
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Routes>
            <Route path="/" element={<DoorForm />} />
            <Route path="/form" element={<Form />} />
            <Route path="/query" element={<QueryForm />} />
            <Route path="/query/:paramUrl" element={<QueryFromUrlResult />} />
            <Route path="/:paramUrl" element={<QueryFromUrlResult />} />
            <Route path="/test/:param" element={<RouteWithParam />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
