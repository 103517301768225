import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const API_PATH = process.env.REACT_APP_API_PATH;
const API_DOOR = `${API_PATH}/door`;

export default function DoorForm() {
  const navigate = useNavigate();
  const [passWord,setPassward] = useState('')
  const [showError,setShowError] = useState(false);
  const [err,serErr]  = useState('')
  const [isVisible, setIsVisible] = useState(false);
  
  const handleSubmit = async () => {
    try {
      const response = await axios.post(API_DOOR, {key:passWord});
      if (response.status === 200 && response.data.success) {
          sessionStorage.setItem('token',passWord);
          setShowError(false);
          serErr('');
          navigate('/form', { replace: true }); 
      }else {
        setShowError(true);
        serErr('验证码错误');
      }
    } catch (error) {
      console.error("Error creating new order:", error);
      alert('提交失败');
    }
  };

  const handleKeyPress = (event) => {
    // 检查按下的是否是 "Enter" 键
    if (event.key === 'Enter') {
      // 防止执行默认的行为（例如提交表单）
      event.preventDefault();

      // 调用提交函数
      handleSubmit();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000);  

    return () => clearTimeout(timer);
  }, []); 

  if (!isVisible) {
    return null; 
  }

  return (
    <div>
      <Dialog open={true} 
          className="animate__animated animate__fadeIn"
          BackdropProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
      >
        <DialogTitle style={{justifyContent:'center',display:'flex',fontSize:'16px'}}>Why are you here</DialogTitle>
        <DialogContent>
            <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>.                  
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex',marginRight:'10px' }}>
                        <TextField
                        fullWidth 
                        error = {showError}
                        id="outlined-number"
                        size="small"
                        label="验证码 Key"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={err}
                        value={passWord}
                        onChange={(e) => setPassward(e.target.value)}
                        onKeyPress={handleKeyPress}
                        />
                    </Grid>
                </Grid>
            </Box> 
        </DialogContent>
        <DialogActions>
              <Button onClick={handleSubmit}>送出</Button>  
        </DialogActions>
      </Dialog>
    </div>
  );
}
