// /query/:paramUrl
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';

const API_PATH = process.env.REACT_APP_API_PATH;
const API_GET = `${API_PATH}/query`;
const PRICE = process.env.REACT_APP_PRICE;

export default function QueryFromUrlResult() {
  const navigate = useNavigate();
  const { paramUrl } = useParams(); // 假設你的路由參數名稱是 param1
  const [open, setOpen] = React.useState(true);
  const [result, setResult] = React.useState(null);
  const [isCountingDown, setIsCountingDown] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);

  // 1698342110051
  function CountDownTimer({ targetDate }) {
    const [countdown, setCountdown] = useState({ minutes: 0, seconds: 0 });
  
    useEffect(() => {
      // Calculate the initial countdown
      const now = new Date().getTime();
      const targetTime = new Date(targetDate).getTime();
      const timeDifference = targetTime - now;
  
      if (timeDifference > 0) {
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
        setCountdown({ minutes, seconds });
  
        // Update the countdown every second
        const intervalId = setInterval(() => {
          if (countdown.seconds === 0) {
            if (countdown.minutes === 0) {
              clearInterval(intervalId);
            } else {
              setCountdown({ minutes: countdown.minutes - 1, seconds: 59 });
            }
          } else {
            setCountdown({ minutes: countdown.minutes, seconds: countdown.seconds - 1 });
          }
        }, 1000);
        setIsCountingDown(true);
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
      } else {
        // If the target date has already passed, set the countdown to 0
        setCountdown({ minutes: 0, seconds: 0 });
        setIsCountingDown(false);
      }
    }, [targetDate, countdown.minutes, countdown.seconds]);
  
    return (
      <>
        <span key={uuidv4()}>
          {(countdown.minutes ? countdown.minutes + ' minutes ' : "")}{(countdown.seconds ? countdown.seconds + ' seconds ' : "")} {countdown.seconds===0 && countdown.minutes===0 && !isPaid ? "訂單逾期" : ""}
        </span>
        { isCountingDown || !isPaid ? <br /> : null }
      </>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleQuery();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [paramUrl, navigate]);

  useEffect(() => {
    if (result && result.account !== null && result.account !== '' && result.account !== undefined) {
      handleParamQuery()
    }
  }, [result]);

  useEffect(() => {
    if (result !== null) {
      handleClickOpen()
    }
  }, [result]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(API_GET, { account: paramUrl });
        if (response.status === 200 && response.data && response.data.account ) {
          setResult(response.data); // 把獲取到的資料設定給 result
        } else {
          // 處理錯誤情況
          navigate('/'); // 或者其他的錯誤處理方式
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate('/error');
      }
    };

    fetchData();
  }, [paramUrl, navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleQuery = async () => {
    try {
      const response = await axios.post(API_GET, { account: paramUrl });
      if (response.status === 200 && response.data.msg === undefined) {
        setIsPaid(response.data.completed)
        setResult(response.data)
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  const handleParamQuery = async () => {
    handleClose()
    try {
      const response = await axios.post(API_GET, { account: result.account });
      if (response.status === 200 && response.data.msg === undefined) {
        setIsPaid(response.data.completed)
        // setResult(response.data)
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  
  return (

    <div>
      {result && (
        <Dialog open={open}
          BackdropProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            style: {
              width: '600px',
            },
          }}
        >
          <DialogTitle style={{ justifyContent: 'center', display: 'flex', fontSize: '16px' }}>Why Are You Here?</DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 0 }}>
              <Grid container spacing={2}>
                {/* <Grid key={uuidv4()} item xs={12} sx={{ mb: -3 }} style={{ justifyContent: 'center', display: 'flex', fontSize: '14px' }}>
                  {`isCountingDown: ${isCountingDown}, isPaid: ${isPaid}, isValid: ${isValid}`}
                </Grid> */}
                {(isCountingDown && !isPaid) ? (
                  <Grid key={uuidv4()} item xs={12} sx={{ mb: -3 }} style={{ justifyContent: 'center', display: 'flex', fontSize: '14px' }}>
                    {`Please send $${Number(result.info.Amount) * PRICE} USDT to below address:` /* comment */}
                  </Grid>) : null}
                <Grid key={uuidv4()} item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                  {!isPaid ? (
                    isCountingDown ? <img src={result.qr} alt='qrcode' /> : <Alert severity="warning" style={{ marginTop: '10px' }}>该订单已失效。</Alert>
                  ) : (
                    <Alert severity="success" style={{ marginTop: '10px' }}>交易已完成 我们会尽速寄出您的品项</Alert>
                  )}
                </Grid>
                {(isPaid) ?
                  <>
                    <Grid key={uuidv4()} item xs={12} sx={{ mt: -2 }} style={{ justifyContent: 'center', display: 'flex' }}>
                      <Alert severity="info" style={{ marginTop: '10px' }}>效期: {new Date(result.info?.Expire).toLocaleString()}<br />續用請匯款至同一地址, 金額: ${Number(result.info.Amount) * 30}</Alert>
                    </Grid>
                    <Grid key={uuidv4()} item xs={12} sx={{ mt: -2 }} style={{ justifyContent: 'center', display: 'flex' }}> <img src={result.qr} alt='qrcode' /> </Grid>
                  </>
                  : null
                }
                <Grid key={uuidv4()} item xs={12} sx={{ mt: 0 }} style={{ textAlign: 'center', fontSize: '14px' }}>
                  { !isPaid && !isCountingDown ? (
                    <>
                      <s>{result.walletB58}</s><br />
                      <span>expired</span>
                    </>
                  ) : (
                    <span>{result.walletB58}</span>
                  )}
                </Grid>
                <Grid key={uuidv4()} item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        
                        <CountDownTimer targetDate={result.last} />
                        订单数量:{result.info.Amount}<br />
                        总额: {Number(result.info.Amount) * PRICE} USDT<br />
                        收件人:{result.info.Name}<br />
                        电话:{result.info.Phone}<br />
                        国家:{result.info.Country}<br />
                        邮政编码:{result.info.Code}<br />
                        地址:{result.info.Address}<br />
                        订单建立时间:{new Date(result.created).toLocaleString("lt")}<br />
                        订单有效时间:{new Date(result.last).toLocaleString("lt")}<br />
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        订单编号:{result.account}<br />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

