import React, { useState } from 'react';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import QueryResult from './QueryResult';
import { useNavigate } from 'react-router-dom';


const API_PATH = process.env.REACT_APP_API_PATH;
const API_GET = `${API_PATH}/query`;

export default function QueryForm() {
  const navigate = useNavigate();
  const [orderNo,setOrderNo] = useState('')
  const [showError,setShowError] = useState(false);
  const [err,serErr]  = useState('')
  const [queryStatus,setQueryStatus] = useState(false);
  const [result,setResult] = useState(null)

  
  const handleParamQuery = async () => {
    try {
          const response = await axios.post(API_GET, {account:orderNo});
          if (response.status === 200 && response.data.msg === undefined) {            
            setResult(response.data);
            setShowError(false);
            serErr('');
            setQueryStatus(true);
            // window.history.pushState({}, '', `/query/${orderNo}`);
            navigate(`/query/${orderNo}`);
          }else {
            setShowError(true);
            serErr('订单编号错误')
            setQueryStatus(false);
          }
    } catch (error) {
        setShowError(true);
        serErr('订单编号错误');
        setQueryStatus(false);
        console.error("Error logging in:", error);
    }        
  }

  const handleKeyPress = (event) => {
    // 检查按下的是否是 "Enter" 键
    if (event.key === 'Enter') {
      // 防止执行默认的行为（例如提交表单）
      event.preventDefault();

      // 调用提交函数
      handleParamQuery();
    }
  };


  if (queryStatus) {
    return <QueryResult queryResult={result}/>; 
  }

  return (
    <div>
      <Dialog open={true} 
          BackdropProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
      >
        <DialogTitle style={{justifyContent:'center',display:'flex',fontSize:'16px'}}>Why are you here</DialogTitle>
        <DialogContent>
            <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>.                  
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex',marginRight:'10px' }}>
                        <TextField
                        fullWidth 
                        error = {showError}
                        id="outlined-number"
                        size="small"
                        label="订单编号"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={err}
                        value={orderNo}
                        onChange={(e) => setOrderNo(e.target.value)}
                        onKeyPress={handleKeyPress}
                        />
                    </Grid>
                </Grid>
            </Box> 
        </DialogContent>
        <DialogActions>
              <Button onClick={handleParamQuery}>送出</Button>  
        </DialogActions>
      </Dialog>
    </div>
  );
}
